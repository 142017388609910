export function formatText(text: string | null) {
	if (!text) return ''
	let formattedText = text.replace('bmw', 'BMW')
	formattedText = formattedText.replace('byd', 'BYD')
	formattedText = formattedText.replace(' ev ', ' EV ')
	formattedText = formattedText.replace('xc40', 'XC40')
	formattedText = formattedText.replace(' se ', ' SE ')
	formattedText = formattedText.replace('gwm', 'GWM')
	formattedText = formattedText.replace(' gt', ' GT')
	formattedText = formattedText.replace('xrx ', ' XRX ')
	formattedText = formattedText.replace('jac ', ' JAC ')

	// Capitalize the first letter of every word
	formattedText = formattedText
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')

	return formattedText
}

export function formatState(state: string | null) {
	if (!state) return ''

	const match = state.match(/\(([^)]{2})\)/)
	const stateCode = match ? match[1].toUpperCase() : ''

	const cityName = state
		.replace(/\(([^)]{2})\)/, '')
		.trim()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')

	return `${cityName} (${stateCode})`
}
